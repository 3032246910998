import config from '../../config';
import Base from './Base';

export default class API extends Base {

    async get(method, params) {
        const promiseResp = fetch(config.apiUrl + '/' + method + "?" + config.slow + 'token=' + this.state.token
            + "&" + new URLSearchParams(params).toString(), {
            method: 'GET',
            headers: this.headers,
        });
        return this.wrapResponse(promiseResp);
    }

    async post(method, params) {
        const promiseResp = fetch(config.apiUrl + '/' + method + '?' + config.slow + 'token=' + this.state.token, {
            method: 'POST',
            headers: this.headers,
            body: JSON.stringify({...params}),
        });
        return this.wrapResponse(promiseResp);
    }

}
