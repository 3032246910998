import React from 'react';
import './Tips.scss';
import {ReactComponent as ImgSuccess} from "../common/img/success_circle.svg";
import {ReactComponent as ImgAdv} from "./img/bcc_adv.svg";
import {useTranslation} from "react-i18next";
import {getQueryParams} from "../../lib/helpers/params";

export default function Success() {
  const {t} = useTranslation();

  const params = getQueryParams()

  const trackId = localStorage.getItem("trackId");

  return (<div className="tips central">
    <div className="app-container payment__app-container">
      <div className="admin__center justify-content-center align-items-center">
        <div className="success-sum">
          <ImgSuccess width="25" height="25"/>
          <p className="big-font">&nbsp;{params["s"]} ₸</p>
        </div>
        <p className="circle-success__text success">{t("ThankForYouPayment")}</p>
        <div className="success-adv" onClick={() => window.open("https://www.bcc.kz/kartakarta/?&utm_source=partner_coinbox&utm_medium=banner&tx_id=" + params['id'] + "&client_id=" + trackId, '_blank')}>
          <ImgAdv />
        </div>
      </div>
    </div>
  </div>);
}