import {getUniqueArray} from "../../Utils/getUniqueArray";

export const getTransactions = async (
  api,
  endpoint,
  requestParams,
  setLoadTransactions,
  separateToLines,
  setTransactions,
  setHasMore,
  setTotalSum
) => {
  setLoadTransactions(true);
  const res = await api.get(endpoint, { ...requestParams });

  if (!res.success || !res.data.transactions) {
    return;
  }

  if (!isNaN(res.data.totalSum)) {
    setTotalSum(res.data.totalSum)
  }

  if (res.data.transactions.length < 30) {
    setHasMore(false);
  }
  if (requestParams.p === 0) {
    setTransactions(res.data.transactions);
    separateToLines(res.data.transactions);
    setLoadTransactions(false);
  } else {
    setTransactions((pv) => {
      separateToLines(getUniqueArray([...pv, ...res.data.transactions]));
      setLoadTransactions(false);
      return getUniqueArray([...pv, ...res.data.transactions]);
    });
  }
};
