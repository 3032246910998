import React, {useContext} from 'react';
import {
    Link
} from "react-router-dom";

import './LeftMenu.scss';

import {ReactComponent as ImgHome} from "../common/img/home.svg";
import {ReactComponent as ImgBag} from "../common/img/bag.svg";
import {ReactComponent as ImgProfile} from "../common/img/profile.svg";
import {ReactComponent as Imgkzt} from "../common/img/kzt.svg";
import {ReactComponent as ImgQuit} from "../common/img/quit.svg";
import {ReactComponent as ImgLetter} from "../common/img/letter.svg";
import {ReactComponent as ImgContacts} from "../common/img/document.svg";

import FlatBtn from "../common/FlatBtn";
import {AuthContext} from "../../lib/auth/Context";
import {useTranslation} from "react-i18next";

export default function LeftMenu({onClose}) {

    const {state, dispatch} = useContext(AuthContext);
    const {t, i18n} = useTranslation();

    return (
        <div className="menu central">
            <div className="app-container admin__app-container">
                <div className="menu__header">
                    <p className="header-text">{t("menu")}</p>
                    <FlatBtn type="cross" onClick={() => onClose()}/>
                </div>
                <div className="locales">
                    {["en", "ru", "kk"].map((locale, i) => <div key={locale} onClick={() => i18n.changeLanguage(locale)}>
                        <div className="menu__item-text" style={{color: i18n.language.split("-")[0] === locale ? "white" : "gray"}}>
                            {locale !== "kk" ? locale.toUpperCase() : "KZ"}
                        </div>
                    </div>)}
                </div>
                <div className="menu__content">
                    <div className="menu__item">
                        <ImgHome/>
                        <Link
                            to="/main"
                            className="menu__item-text"
                            onClick={() => onClose()}
                        >
                            {t("main")}
                        </Link>
                    </div>
                    <div className="menu__item">
                        <ImgBag/>
                        <Link
                            to="/company"
                            className="menu__item-text"
                            onClick={() => onClose()}
                        >
                            {t("companies")}
                        </Link>
                    </div>
                    <div className="menu__item">
                        <ImgProfile/>
                        <Link
                            to="/profile"
                            className="menu__item-text"
                            onClick={() => onClose()}
                        >
                            {t("profile")}
                        </Link>
                    </div>
                    <div className="menu__item">
                        <Imgkzt/>
                        <Link
                            to="/money"
                            className="menu__item-text"
                            onClick={() => onClose()}
                        >
                            {t("money")}
                        </Link>
                    </div>
                    <div className="menu__item">
                        <ImgLetter/>
                        <a
                            className="menu__item-text"
                            target="_blank"
                            href={"https://t.me/CoinboxNotifyBot?start=" + state.id}
                            rel="noreferrer"
                        >
                            {t("connectTelegram")}
                        </a>
                    </div>
                    <div className="menu__item">
                        <ImgContacts/>
                        <Link
                            to="/contacts"
                            className="menu__item-text"
                            onClick={() => onClose()}
                        >
                            {t("contacts")}
                        </Link>
                    </div>
                </div>
                {/*<a href={config.publicUrl + "/tips?u=" + state.number}>*/}
                {/*    <div className="menu_qr">*/}
                {/*        <QRCode value={config.publicUrl + "/tips?u=" + state.number} size={100} />*/}
                {/*    </div>*/}
                {/*</a>*/}

                <div className="menu__footer">
                    <ImgQuit/>
                    <p
                        className="menu__item-text"
                        onClick={() => {
                            dispatch({type: 'logout'})
                            window.location.href = "/auth";
                        }}>
                        {t("quite")}
                    </p>
                </div>
            </div>
        </div>
    );
}
