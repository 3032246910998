import React from 'react';
import './Tips.scss';
import {ReactComponent as ImgFail} from "../common/img/fail_circle.svg";
import {useTranslation} from "react-i18next";

export default function Fail() {
    const {t} = useTranslation();

    return (
        <div className="tips central">
            <div className="app-container payment__app-container">
                <div className="admin__center justify-content-center align-items-center">
                    <div className="circle-success">
                        <ImgFail/>
                        <p className="circle-success__text red-text">{t("somethingWentWrong")}</p>
                    </div>
                </div>
            </div>
        </div>
    );
}