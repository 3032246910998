import "./ImgInput.scss";
import InputMask from "react-input-mask";

import { ReactComponent as ImgProfile } from "./img/profile.svg";
import { ReactComponent as ImgHome } from "./img/home.svg";
import { ReactComponent as ImgPeople } from "./img/people.svg";
import { ReactComponent as ImgQuit } from "./img/quit.svg";
import { ReactComponent as ImgLetter } from "./img/letter.svg";
import { ReactComponent as ImgKzt } from "./img/kzt.svg";
import { ReactComponent as ImgDoc } from "./img/document.svg";
import { ReactComponent as ImgCard } from "./img/card.svg";
import { ReactComponent as ImgLock } from "./img/lock.svg";
import { MdOutlineBusinessCenter, MdOutlinePhone } from "react-icons/md";

export function ImgInput({
  image,
  imageType,
  placeholder,
  field,
  type = "text",
  pattern,
  onChange,
  theme = "dark",
    complete = "on",
}) {
  // eslint-disable-next-line default-case
  switch (imageType) {
    case "profile":
      image = <ImgProfile />;
      break;
    case "home":
      image = <ImgHome />;
      break;
    case "phone":
      image = <MdOutlinePhone />;
      break;
    case "bag":
      image = <MdOutlineBusinessCenter />;
      break;
    case "people":
      image = <ImgPeople />;
      break;
    case "quit":
      image = <ImgQuit />;
      break;
    case "letter":
      image = <ImgLetter />;
      break;
    case "kzt":
      image = <ImgKzt />;
      break;
    case "doc":
      image = <ImgDoc />;
      break;
    case "card":
      image = <ImgCard />;
      break;
    case "lock":
      image = <ImgLock />;
      break;
  }

  return (
    <div className="lined-input">
      {image && <div className="lined-input__img">{image}</div>}
      <div className="lined-input__text">
        <input
          type={type}
          pattern={pattern}
          value={field}
          className={"floating-input floating-input_theme_" + theme}
          onChange={onChange}
          required
          autoComplete={complete}
        />
        <label className="floating-label">{placeholder}</label>
      </div>
    </div>
  );
}

export function PhoneInput({ image, placeholder, onChange, value }) {
  if (!image) {
    image = <MdOutlinePhone />;
  }

  const beforeMaskedValueChange = (newState, oldState, userInput) => {

    if (userInput && userInput.length > 10) {
      userInput = userInput.replace(/\D/g, "")
      return {
        value: `+7${userInput.slice(-10)}`,
        selection: newState.selection,
      };
    } else {
      return {
        value: newState.value,
        selection: newState.selection,
      };
    }
  };

  return (
    <div className="lined-input">
      <div className="lined-input__img">{image}</div>
      <div className="lined-input__text">
        <InputMask
          value={value}
          inputMode="numeric"
          className="floating-input floating-input_theme_dark"
          mask="+7 (999) 999-99-99"
          maskChar={null}
          onChange={onChange}
          required
          beforeMaskedValueChange={beforeMaskedValueChange}
        />
        <label className="floating-label">{placeholder}</label>
      </div>
    </div>
  );
}

export function CardInput({ image, placeholder, onChange }) {
  return (
    <div className="lined-input">
      <div className="lined-input__img">{image}</div>
      <div className="lined-input__text">
        <InputMask
          inputMode="numeric"
          className="floating-input floating-input_theme_dark"
          mask="9999 9999 9999 9999"
          maskChar={null}
          onChange={onChange}
          required
        />
        <label className="floating-label">{placeholder}</label>
      </div>
    </div>
  );
}

export function CardTips({ image, placeholder, onChange }) {
  return (
    <div className="lined-input">
      <div style={{ marginRight: "10px" }}>{image}</div>
      <div className="lined-input__text">
        <InputMask
          inputMode="numeric"
          className="floating-input"
          mask="9999 9999 9999 9999   99/99   999"
          maskChar={null}
          onChange={onChange}
          required
        />
        <label className="static-label">{placeholder}</label>
      </div>
    </div>
  );
}
