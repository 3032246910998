import React from "react";

import { TbBrandTelegram, TbPhone, TbBrandWhatsapp } from "react-icons/tb";

import "./Contacts.scss";

import FlatBtn from "../common/FlatBtn";
import { Link, useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";

export default function Contacts() {
  const location = useLocation();
  const { t } = useTranslation();

  const getLightStyle = () => location.search.split("?")[1];

  return (
    <div className={`${"registration central"} ${getLightStyle()}`}>
      <div className="app-container registration__app-container">
        <div className="registration__header">
          {getLightStyle() !== "light" && <Link to="/" className={`${getLightStyle()}`}>
            <FlatBtn type="prev" />
          </Link>}
          <p className="header-text">{t("contacts")}</p>
        </div>
        <div className="contacts">
          <div className="auth__inputs d-flex flex-column">
            <div className="input-block__item justify-content-start">
              <TbBrandTelegram />
              &nbsp;&nbsp;&nbsp;
              <a
                href="https://t.me/+77770353688"
                target="_blank"
                className={`${getLightStyle()}`}
                rel="noreferrer"
              >
                Telegram
              </a>
            </div>
            <div className="input-block__item justify-content-start">
              <TbBrandWhatsapp />
              &nbsp;&nbsp;&nbsp;
              <a
                href="https://wa.me/+77770353688"
                target="_blank"
                className={`${getLightStyle()}`}
                rel="noreferrer"
              >
                WhatsApp
              </a>
            </div>
            <div className="input-block__item justify-content-start">
              <TbPhone />
              &nbsp;&nbsp;&nbsp;
              <a href="tel:+77770353688" className={`${getLightStyle()}`}>
                +7 777 035 36 88
              </a>
            </div>
            <div className="input-block__item justify-content-start end">
              <p className={`${getLightStyle()}`}>
                Email:&nbsp;&nbsp;&nbsp;coinbox.kz@gmail.com
              </p>
            </div>
            {getLightStyle() !== 'light' &&
            <div className="input-block__item justify-content-start end">
              <p>
                {t("offer")}:
                <a
                  href="https://coinbox.kz/1oferta.pdf"
                  target="_blank"
                  className={`${getLightStyle()}`}
                  rel="noreferrer"
                >
                  &nbsp;&nbsp;&nbsp;oferta.pdf
                </a>
              </p>
            </div>
            }
          </div>
        </div>
      </div>
    </div>
  );
}
