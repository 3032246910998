import './SuccessResult.scss'
import {ReactComponent as ImgSuccess} from "./img/success_circle.svg";
import React from "react";

export default function SuccessResult({text, onClick}) {

  return (
    <>
        <div className="success-result">
            <ImgSuccess/>
            <p className="success-result__text success">{text}</p>
        </div>
    </>
  );
}