import {useEffect, useState} from "react";
import {getMinSecReverse} from "../../lib/helpers/time";

export default function CountDownTimer({start, onFinish}) {

    const [duration, setDuration] = useState(start)
    const [timer, setTimer] = useState(getMinSecReverse(duration))

    useEffect(()=> {
        if (duration <= 0 ){
            onFinish();
            return;
        }

        const timerID = setInterval(() => {
            setDuration(duration-1)
            setTimer(getMinSecReverse(duration-1))
        }, 1000);
        return () => {
            clearInterval(timerID)
        }
    })


    return (
        <>
            {timer}
        </>
    )
};