import './ActionButton.scss';
import Loader from "./Loader";

export default function ActionButton({text, onClick = ()=>{}, disabled, loader }) {
  let className = "action-btn";
  if (disabled) {
    className += " action-btn_style_disabled";
  } else {
    className += " action-btn_style_active";
  }

  return (
    <button onClick={!disabled ? onClick : ()=>{}} className={className}>{!loader && text}{loader && <Loader type="circle" />}</button>
  );
}

export function ActionButtonBlack({text, onClick = ()=>{}, disabled, loader }) {
  let className = "action-btn action-btn_style_black";

  if (disabled) {
    className += " action-btn_style_disabled";
  }
  return (
      <button onClick={!disabled ? onClick : ()=>{}} className={className}>{!loader && text}{loader && <Loader type="circle" />}</button>
  );
}

export function ActionButtonWhite({text, onClick = ()=>{}, disabled, loader }) {
  let className = "action-btn action-btn_style_white";

  if (disabled) {
    className += " action-btn_style_disabled";
  }
  return (
    <button onClick={!disabled ? onClick : ()=>{}} className={className}>{!loader && text}{loader && <Loader type="circle" />}</button>
  );
}
