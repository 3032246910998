var host = window.location.hostname
var port = (window.location.port ? ':' + window.location.port : '')

const config = {
    responseTimeout: 10000,
    apiUrl: "https://test-api.coinbox.kz",
    publicUrl: "https://www-test.coinbox.kz" + port,
    payUrl: "https://www-test.coinbox.kz" + port,
    rights: {
        root: 1 << 0,
        admin: 1 << 1,
        company: 1 << 2,
        tips: 1 << 3,
    }
}

config.slow = ""

if (process.env.NODE_ENV === 'development') {
    config.apiUrl = "https://test-api.coinbox.kz";
    config.slow = "slow=1&";
    config.publicUrl = "https://" + host + port;
    config.payUrl = "https://" + host + port;
}

export default config
