import './ImageItem.scss';

export default function ImageItem({avatar, text, rightIcon, onClickItem = () => {}, onClickRightIcon = () => {}}) {

  return (
    <div className="img-item" >
      <div onClick={onClickItem} className="img-item__avatar">
        {avatar}
      </div>
      <div onClick={onClickItem} className="img-item__text">
        {text}
      </div>
      <div className="img-item__right-icon" onClick={onClickRightIcon}>
        {rightIcon}
      </div>
    </div>
  );
}
