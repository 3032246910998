import * as React from "react";
import styled from "styled-components";
import {motion} from "framer-motion";

import "./Notification.scss"

import {ReactComponent as ImgNotifyInfo} from "./img/notify_info.svg";
import {ReactComponent as ImgNotifySuccess} from "./img/notify_success.svg";
import {ReactComponent as ImgNotifyWarning} from "./img/notify_warning.svg";
import {ReactComponent as ImgNotifyError} from "./img/notify_error.svg";

/**
 * Some basic styles
 */

// Notice how we're wrapping 'motion-div'
// styled-components generates a className and injects it into the motion.div
const Base = styled(motion.div)`
  width: 100%;
  //height: 84px;
  border-radius: 16px;
  display: flex;
  margin-top: 5px;
  z-index: 9999;
`;

function Notification({title, description, type, onClose, onMore}) {

    let badge;
    switch (type) {
        case "success":
            badge = <ImgNotifySuccess className="notification__badge" />
            break;
        case "warning":
            badge = <ImgNotifyWarning className="notification__badge" />
            break;
        case "error":
            badge = <ImgNotifyError className="notification__badge" />
            break;
        default:
            badge = <ImgNotifyInfo className="notification__badge" />
    }

    if (!type) {
        type = "error"
    }

    return (
        <Base
            initial={{opacity: 0, scale: 0.5, y: 0}} // animate from
            animate={{opacity: 1, scale: 1, y: 10}} // animate to
            exit={{opacity: 0, scale: 0.5, y: 0}} // animate exit
            transition={{
                type: "spring",
                stiffness: 1000,
                damping: 40
            }}
            positionTransition // auto animates the element when it's position changes
        >
            <div className={"notification shadow-blue notification__theme_" + type} onClick={onClose}>
                {badge}
                <div className="notification__info">
                    <div className="notification__title">{title}</div>
                    <div className="notification__description">{description}</div>
                </div>
            </div>
        </Base>
    );
}

export default Notification;
