import React, { useState, useContext } from "react";

import "./SimpleRegistration.scss";

import useApi from "../../../lib/hooks/useAPI";

import { AuthContext } from "../../../lib/auth/Context";
import useNotify from "../../../lib/hooks/Notify";
import FlatBtn from "../../common/FlatBtn";
import { Link, useParams } from 'react-router-dom'
import { ImgInput, PhoneInput } from "../../common/ImgInput";

import { ReactComponent as ImgProfile } from "../../common/img/profile.svg";
import { ReactComponent as ImgPhone } from "../../common/img/phone.svg";
import { ReactComponent as ImgLock } from "../../common/img/lock.svg";

import ActionButton from "../../common/ActionButton";
import { useTranslation } from "react-i18next";

export default function Registration() {
    const api = useApi();
    const notify = useNotify();

    const { companyId } = useParams();

    const [phone, setPhone] = useState("");
    const [name, setName] = useState("");
    const [lastName, setLastName] = useState("")
    const [password, setPassword] = useState("");

    const [saveLoader, setSaveLoader] = useState(false);

    const { t } = useTranslation();

    const onPhoneChange = (val) => {
        setPhone("+" + val.replace(/\D/g, ""));
    };

    const handleRegister = async () => {

        setSaveLoader(true);
        const registerRes = await api.post("company.createEmployee", {
            lastName: lastName,
            name: name,
            phone: phone,
            password: password,
            companyId: companyId
        });
        setSaveLoader(false);
        if (!registerRes.success) {
            return;
        }

        notify({ type: "success", title: t("youHaveSuccessfullyRegistered") });
        setTimeout(() => window.location.reload(), 1500);
    };

    return (
        <div className="registration central">
            <div className="app-container registration__app-container">
                <div className="registration__header">
                    <Link to={"/company/" + companyId}>
                        <FlatBtn type="prev" />
                    </Link>
                    <p className="header-text">{t("registration")}</p>
                </div>
                    <div className="registration__inputs">
                    <div className="registration__input">
                            <ImgInput
                                image={<ImgProfile />}
                                placeholder={t("lastName")}
                                autoComplete="off"
                                onChange={(e) => setLastName(e.target.value)}
                            />
                        </div>
                        <div className="registration__input">
                            <ImgInput
                                image={<ImgProfile />}
                                placeholder={t("name")}
                                autoComplete="off"
                                onChange={(e) => setName(e.target.value)}
                            />
                        </div>
                        <div className="registration__input">
                            <PhoneInput
                                image={<ImgPhone />}
                                placeholder={t("phoneNumber")}
                                autoComplete="off"
                                onChange={(e) => onPhoneChange(e.currentTarget.value)}
                                field={phone}
                            />
                        </div>
                        <div className="registration__input">
                            <ImgInput
                                image={<ImgLock />}
                                placeholder={t("password")}
                                type="text"
                                autoComplete="off"
                                onChange={(e) => setPassword(e.target.value)}
                            />
                        </div>
                    </div>

                <div className="registration__controls">
                        <ActionButton
                            text={t("register")}
                            onClick={() => handleRegister()}
                            loader={saveLoader}
                            disabled={password.length < 4 || !name.length || phone.length < 12}
                        />
                </div>
            </div>
        </div>
    );
}
