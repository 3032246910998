import * as React from 'react';
import { createPortal } from 'react-dom';
import styled from 'styled-components';
import shortId from 'shortid'
import { AnimatePresence } from 'framer-motion';

import Notification from './Notification';

export const NotifyContext = React.createContext();

const NotificationsContainer = styled.div`
  padding: 0 10px;
  display: flex;
  top: 20px;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  position: fixed;
  width: 100%;
  z-index: 2;
  `;

export function useNotifications() {
  const [notifications, setNotifications] = React.useState([]);

  const notify = React.useCallback(notificationPayload => {

    const id = shortId();

    function removeNotification() {
      setNotifications(notifications => notifications.filter(n => n.id !== id));
    }

    setNotifications(notifications => [
      ...notifications,
      { id, onClose: removeNotification, ...notificationPayload }
    ]);

    const visibleTime = notificationPayload.timeout ? notificationPayload.timeout : 5000

    setTimeout(removeNotification, visibleTime);
  }, []);

  return { notify, notifications };
}

function NotificationProvider({ children, notify, notifications }) {

  const [notificationRoot, setNotificationRoot] = React.useState(null)

  React.useEffect(() => {
    const element = document.createElement("div");
    document.body.appendChild(element);
    setNotificationRoot(element)

    return () => {
      document.body.removeChild(element)
    }
  }, []);

  return (
    <>
      <NotifyContext.Provider value={notify}>{children}</NotifyContext.Provider>
      {notificationRoot &&
      createPortal(
        <NotificationsContainer>
          <AnimatePresence>
            {notifications.map(notification => (
              <Notification key={notification.id} {...notification} />
            ))}
          </AnimatePresence>
        </NotificationsContainer>,
        notificationRoot
      )}
    </>
  );
}

export default NotificationProvider;
