export function getHourMinSec(date) {
    const created = new Date(date)
    const now = new Date()
    let delta = Math.abs(now - created);
    return durationToHourMinSec(delta);
}

export function getMinSecReverse(duration) {
    let timer = duration, minutes, seconds;
    minutes = parseInt(timer / 60, 10);
    seconds = parseInt(timer % 60, 10);

    minutes = minutes < 10 ? "0" + minutes : minutes;
    seconds = seconds < 10 ? "0" + seconds : seconds;

    return minutes + ":" + seconds;
}

export function durationToHourMinSec(duration) {
    duration /= 1000
    let hours = Math.floor(duration / 3600);
    let minutes = Math.floor(duration / 60) % 60;
    duration -= minutes * 60;
    if ((minutes / 10) < 1) {
        minutes = "0" + minutes
    }
    let seconds = Math.floor(duration % 60);
    if ((seconds / 10) < 1) {
        seconds = "0" + seconds
    }
    let res = minutes + ":" + seconds;
    if (hours) {
        res = hours + ":" + res;
    }
    return res;
}

export function getNamedMonth(monthNum) {
    const arrMonth = {0: "января", 1: "февраля", 2: "марта", 3: "апреля", 4: "мая", 5: "июня", 6: "июля", 7: "августа", 8: "сентября", 9: "октября", 10: "ноября", 11: "декабря"}
    return arrMonth[monthNum] ?? monthNum;
}