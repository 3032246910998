import React, {useState} from 'react';

import './CardList.scss';

import useApi from "../../lib/hooks/useAPI";
import FlatBtn from "../common/FlatBtn";

import ActionButton from "../common/ActionButton";

import {ReactComponent as ImgCard} from "../common/img/master_card.svg";
import {ReactComponent as ImgCross} from "../common/img/cross.svg";
import useNotify from "../../lib/hooks/Notify";
import Loader from "../common/Loader";
import {useTranslation} from "react-i18next";

export default function CardList({cards, setCards, onClose, onPlus}) {

    const api = useApi();

    const notify = useNotify();

    const [deleteLoader, setDeleteLoader] = useState(false);

    const {t} = useTranslation();

    const chooseCardHandler = (pickedId) => {
        const newCards = cards.map(card => {
            if (card.id !== pickedId && card.isPicked) {
                card.isPicked = false
            }

            if (card.id === pickedId) {
                card.isPicked = true
            }

            return card
        })

        setCards(newCards)
    }

    const saveChoosenCardHandler = async () => {
        let pickedId;
        cards.forEach(card => {
            if (card.isPicked) {
                pickedId = card.id
            }
        })

        const res = await api.post("card.choose", {'cardId': pickedId})
        if (!res.success) {
            return
        }

        notify({type: "success", title: t("success"), description: "ok"})
    }

    const cardDeleteHandler = async (cardId) => {
        if (!window.confirm(`${t("removeCard")}?`)) {
            return
        }

        setDeleteLoader(true)
        const res = await api.post("card.delete", {'cardId': cardId})
        setDeleteLoader(false)
        if (!res.success) {
            return
        }
        setCards([])

        notify({type: "success", title: t("success"), description: "ok"})
        setTimeout(() => window.location.reload(), 500);
    }

    return (
        <div className="admin central noselect">
            <div className="app-container admin__app-container">
                <div className="admin__header">
                    <FlatBtn type="prev" onClick={() => onClose()}/>
                    <p className="header-text">{t("linkedCards")}</p>
                    {!cards && <FlatBtn type="plus" onClick={() => onPlus()}/>}
                </div>
                <div className="admin__center justify-content-center align-items-center">
                    <div className="card-list">
                        {cards.map(card => {
                            return <>
                                {deleteLoader && <Loader type="circle"/>}
                                {!deleteLoader &&
                                    <div className="card-list__item" onClick={() => chooseCardHandler(card.id)}>
                                        <div className="card-list__img"><ImgCard/></div>
                                        <div className="card-list__info">
                                            <p className="card-name">{card.issuer}</p>
                                            <p className="card-number">{card.number}</p>
                                        </div>
                                        <div onClick={() => cardDeleteHandler(card.id)} className="card-list__pick"><ImgCross/></div>
                                    </div>
                                }
                            </>
                        })}
                    </div>
                </div>
                <div className="admin__footer">
                    {!cards && <ActionButton onClick={() => saveChoosenCardHandler()} text={t("save")}/>}
                </div>
            </div>
        </div>
    );
}
