import React  from "react";
import { Transaction } from "../Transaction";
import { Employee } from "../Employee";
import { Company } from "../Company";
import styles from "./List.module.scss";

import { ReactComponent as ImgBasket } from "../../common/img/basket.svg";

export const List = ({
  list,
  type,
  renderCondition,
  id,
  scrollHandler,
  listItemProps,
  hasMore,
}) => {
  // useEffect(() => {
  //   if (renderCondition && hasMore) {
  //     document.getElementById(id).addEventListener("scroll", scrollHandler);
  //   } else {
  //     document.getElementById(id).removeEventListener("scroll", scrollHandler);
  //   }
  // }, [renderCondition, id, scrollHandler, hasMore]);

  if (type === "transactions") {
    return (
      <div className={styles.list}>
        {list?.map((t) => (
          <Transaction
            lines={listItemProps.lines}
            transaction={t}
            isAdmin={listItemProps.isAdmin}
            key={t.id}
          />
        ))}
      </div>
    );
  } else if (type === "employees") {
    return (
      <div className={styles.list}>
        {list?.map((e) => (
          <Employee
            key={e.id}
            emp={e}
            onClickItem={() => listItemProps.onClickItem(e.id)}
            onClickRightIcon={() => listItemProps.onClickRightIcon(e.phone)}
            rightIcon={<ImgBasket/>}
          />
        ))}
      </div>
    );
  } else if (type === "companies") {
    return (
      <div className={styles.list}>
        {list?.map((c) => (
          <Company
            key={c.id}
            company={c}
            to={listItemProps.to ? listItemProps.to + c.id : false}
            rightIcon={listItemProps.rightIcon}
          />
        ))}
      </div>
    );
  } else {
    return <></>;
  }
};
