import React, { useState, useEffect } from 'react'
import './Tips.scss'
import '../admin/Admin.scss'

import useApi from '../../lib/hooks/useAPI'
import { ImgInput } from '../common/ImgInput'

import { ActionButtonWhite } from '../common/ActionButton'

import { getQueryParams } from '../../lib/helpers/params'
import useNotify from '../../lib/hooks/Notify'
import { useParams } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import config from '../../config'
import { FastCommentButton } from '../common/FastCommentButton'
import SwitchBtn from '../common/SwitchBtn'

import { ReactComponent as ImgStarActive } from '../common/img/star_active.svg'
import { ReactComponent as ImgStar } from '../common/img/star.svg'
import { ReactComponent as ImgAvatarEmpty } from '../common/img/avatar_empty.svg'
import { ReactComponent as ImgLogo } from '../common/img/logo_text_dark.svg'
import { ReactComponent as ImgApple } from '../common/img/apple_white_logo.svg'
import { AtmospherePositiveIcon } from '../../Assets/SVG/AtmospherePositiveIcon'
import { AtmosphereNegativeIcon } from '../../Assets/SVG/AtmosphereNegativeIcon'
import { CuisinePositiveIcon } from '../../Assets/SVG/CuisinePositiveIcon'
import { CuisineNegativeIcon } from '../../Assets/SVG/CuisineNegativeIcon'
import { ServicePositiveIcon } from '../../Assets/SVG/ServicePositiveIcon'
import { ServiceNegativeIcon } from '../../Assets/SVG/ServiceNegativeIcon'
import { IoMdArrowBack } from "react-icons/io";
import { AiOutlineCheck } from "react-icons/ai";
import uuid from "react-uuid";

export const Tips = () => {
  const params = getQueryParams()

  let trackId = localStorage.getItem("trackId");
  if (!trackId) {
    trackId = uuid()
    localStorage.setItem("trackId", trackId)
  }

  const {
    t, i18n
  } = useTranslation()

  const sumPercent = (val, percent) => {
    if (!minSum) {
      return val
    }

    return Math.round(minSum * (percent / 100))
  }

  const { companyExternalId } = useParams()
  const userNumber = params['u'] ?? params['U'] ?? 0
  const companyNumber = params['c'] ?? params['C'] ?? 0
  const tableNumber = params['t'] ?? params['T'] ?? ''
  const s = parseInt(params['s'] ?? params['S'] ?? 0)
  const o = params['o'] ?? params['O'] ?? ''
  const minSum = s < 4000 ? 0 : s
  const poller = params['poller']

  const api = useApi()
  const notify = useNotify()

  const [withOrder, setWithOrder] = useState(false)

  const [isLoaded, setIsLoaded] = useState(false)
  const [payLoader, setPayLoader] = useState(false)
  const [applePayLoader, setApplePayLoader] = useState(false)
  const [showApplePay, setShowApplePay] = useState(false)

  const [userInfo, setUserInfo] = useState(null)

  const [estimate, setEstimate] = useState([false, false, false, false, false])
  const [estimateValue, setEstimateValue] = useState(null)
  const [comment, setComment] = useState('')
  const [quickComments, setQuickComments] = useState([])
  const [tipSum, setTipSum] = useState(minSum ? sumPercent(minSum, 12) : 800)
  const [amount, setAmount] = useState(withOrder ? s + tipSum : tipSum)

  const [isCustomTips, setIsCustomTips] = useState(false)
  const [showCustomTips, setShowCustomTips] = useState(false)

  const percents = [10, 12, 15, 20, 25]
  const [quickAmounts] = useState([500, 800, 1000, 1200, 1500])

  const billPercents = [10, 12]
  const [billAmounts] = useState([500, 800])

  const getUserInfo = async () => {
    const res = await api.get('tips.getUserInfo', {
      userNumber: userNumber, companyNumber: companyNumber, companyExternalId: companyExternalId,
      trackId: trackId,
      tableNumber: tableNumber
    })
    if (!res.success) {
      return
    }
    setUserInfo(res.data)
    setIsLoaded(true)
  }

  const pickEstimate = async (value) => {
    if (estimateValue === 0 || estimateValue !== value) {
      setEstimate((pv) => {
        setEstimateValue(value)
        value < 3 && setComment('')
        return pv.map((v, i) => (i < value))
      })
    } else {
      setEstimate((pv) => {
        setEstimateValue(null)
        setComment('')
        setQuickComments([])
        return pv.map(() => false)
      })
    }
  }

  const getHalykToken = function (type) {
    return api.post('halyk.getToken', {
      tokenType: type,
      amount: parseInt(amount),
      userNumber: parseInt(userNumber),
      currency: 'KZT',
      kind: withOrder ? (tipSum > 0 ? 2 : 1) : 0,
      orderSum: s,
      orderNumber: o,
      tipSum: parseInt(tipSum),
      companyNumber: parseInt(companyNumber),
      companyName: userInfo.company ? userInfo.company.name : '',
      tableNumber: tableNumber,
      estimate: estimateValue,
      comment: comment,
      quickComment: quickComments,
      trackId: trackId,
      lang: `${i18n.language.split('-')[0]}`,
    })
  }

  const beforePay = function (isApple) {
    if (!withOrder && tipSum < 200) {
      notify({ title: `${t('minimumAmount')} 200₸` })
      return
    }

    if (withOrder && (0 < tipSum && tipSum < 200)) {
      notify({ title: `${t('minimumAmount')} 200₸` })
      return
    }

    if (tipSum > 50000) {
      notify({ title: `${t('maximumAmount')} 50 000₸` })
      return
    }

    if (showApplePay && isApple) {
      applePay()
    } else {
      pay()
    }
  }

  const pay = async () => {
    setPayLoader(true)
    setTimeout(() => setPayLoader(false), 3000)

    const res = await getHalykToken('page')
    if (!res.success) {
      return
    }

    if (!res.data.token.access_token) {
      notify({ title: "empty token" })
      return
    }

    const kind = withOrder ? (tipSum > 0 ? 2 : 1) : 0

    let description = 'Оплата'
    if (kind === 0) {
      description += ' чаевых: ' + tipSum
    }

    if (kind === 1) {
      description += ' счета: ' + s
    }

    if (kind === 2) {
      description += ' счета: ' + s + ' и чаевых: ' + tipSum
    }

    const createPaymentObject = function (auth, invoiceId, amount, terminal) {
      const paymentObject = {
        invoiceId: invoiceId,
        backLink: 'https://pay.coinbox.kz/tips/success?s=' + amount + '&id=' + invoiceId,
        failureBackLink: window.location.href,
        postLink: poller != "true" && 'https://api.coinbox.kz/halyk.confirm',
        failurePostLink: 'https://api.coinbox.kz/halyk.fail',
        language: `${i18n.language.split('-')[0].toUpperCase()}`,
        description: description,
        accountId: 'CoinBox',
        terminal: terminal,
        amount: amount,
        currency: 'KZT',
        cardSave: true,
      }
      paymentObject.auth = auth
      return paymentObject
    }

    window.halyk.pay(createPaymentObject(res.data.token, res.data.invoiceId, amount, res.data.terminal))
  }

  const applePay = async (e) => {
    setApplePayLoader(true)
    setTimeout(() => setApplePayLoader(false), 1500)
    const paymentRequest = {
      total: {
        label: 'CoinBox', amount: amount
      }, countryCode: 'KZ', currencyCode: 'KZT', merchantCapabilities: ['supports3DS'], supportedNetworks: ['masterCard', 'visa']
    }

    const applePaySession = new window.ApplePaySession(1, paymentRequest)

    applePaySession.onvalidatemerchant = async (event) => {
      const res = await api.post('apple.init', { url: event.validationURL })
      if (!res.success) {
        applePaySession.abort()
        return
      }
      applePaySession.completeMerchantValidation(res.data)
    }

    applePaySession.onpaymentauthorized = async (event) => {
      const resToken = await getHalykToken('apple')
      if (!resToken.success) {
        applePaySession.abort()
        return
      }

      const res = await api.post('apple.complete', {
        halykToken: resToken.data.token.access_token, orderNumber: resToken.data.invoiceId, appleToken: event.payment.token
      })

      if (res.success) {
        applePaySession.completePayment(window.ApplePaySession.STATUS_SUCCESS)
        setTimeout(() => window.location.replace('/tips/success?s=' + amount + '&id=' + resToken.data.invoiceId), 200)
      } else {
        applePaySession.completePayment(window.ApplePaySession.STATUS_FAILURE)
        applePaySession.abort()
      }
    }
    applePaySession.begin()
  }

  const quickCommentHandler = (quickComment) => !quickComments.includes(quickComment) ? setQuickComments((pv) => [...pv, quickComment]) : setQuickComments((pv) => pv.filter((v) => v !== quickComment))

  const getUserPhoto = () => {
    if (userInfo.user.photo) {
      return userInfo.user.photo
    } else if (userInfo.company.photo) {
      return userInfo.company.photo
    } else {
      return null
    }
  }

  const onSetTipSumClick = (sumTip) => {
    setAmount(withOrder ? int(s) + int(sumTip) : int(sumTip));
    setTipSum(int(sumTip));
    setIsCustomTips(false);
  }

  useEffect(() => {
    if (window.ApplePaySession) {
      setShowApplePay(true)
    }

    if (userNumber) {
      getUserInfo()
    }
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    setAmount(withOrder ? int(s) + int(tipSum) : int(tipSum));
  }, [withOrder]) // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    setAmount(withOrder ? int(s) + int(tipSum) : int(tipSum));
  }, [tipSum])

  const int = (val) => {
    if (val) {
      return parseInt(val)
    }
    return 0
  }

  return (<div className="tips central">
    <div className="app-container tips__app-container">
      <div className="tips__service-header">
        <div className="tips__service-image">
          <ImgLogo width="120" height="30" />
        </div>
      </div>
      {isLoaded && (<>
        {(s === 0 || !userInfo.company.billPay) && <div className="tips__employee">
          <div className="tips__employee-info">
            <div className="top-row">
              <div className="avatar-container">
                {getUserPhoto() ? (<img className="tips__employee-image" src={getUserPhoto()} alt="avatar" />) : (
                  <ImgAvatarEmpty widht="64" height="64" />)}
              </div>
              <div className="text-container">
                <div className="tips__employee-name">
                  {userNumber && userInfo.user ? userInfo.user.name : t('employeeNotDefined')}
                </div>
                <div className="tips__company-name">
                  {userInfo.company ? userInfo.company.name : ''}
                </div>
                <div className="tips__employee-subscription">
                  {userNumber && userInfo.user ? userInfo.user.subscription : ''}
                </div>
              </div>
            </div>
          </div>
        </div>}
        {s > 0 && userInfo.company.billPay && <div className="bill">
          <div className="bill__panel gray-text">
            <div className="bill__avatar">
              {getUserPhoto() ? (<img className="bill__avatar-image" src={getUserPhoto()} alt="avatar" />) : (
                <ImgAvatarEmpty widht="64" height="64" />)}
              <div className="avatar__info">
                <div className="avatar__user">{userInfo.user ? userInfo.user.name : t('employeeNotDefined')}</div>
                <div className="avatar__company">{userInfo.company ? userInfo.company.name : ''}</div>
              </div>
            </div>
            <div className="bill__data">
              <div className={withOrder ? "bill__item" : "bill__item cross-text"}>
                <div className="bill__text">Сумма в чеке</div>
                <div className="bill__text">{s} ₸</div>
              </div>
              <div className="bill__item">
                <div className="bill__text">Чаевые</div>
                <div className="bill__text">{tipSum} ₸</div>
              </div>
              <div className="bill__item bill__item_theme_summary">
                <div className="bill__text">Итого:</div>
                <div className="bill__text middle-font">{amount} ₸</div>
              </div>
            </div>
          </div>
          {!showCustomTips && <div className="quick-amount">
            <div key="zero_tips" onClick={() => onSetTipSumClick(0)}
              className={tipSum === 0 ? 'bill-quick-amount__item quick-amount__item_theme_active' : 'bill-quick-amount__item'}>
              <p className="small-caption">без<br />чаевых</p>
            </div>
            {billAmounts.map((val, i) => (<div key={i} onClick={() => onSetTipSumClick(sumPercent(val, billPercents[i]))}
              className={tipSum === sumPercent(val, billPercents[i]) && !isCustomTips ? 'bill-quick-amount__item quick-amount__item_theme_active' : 'bill-quick-amount__item'}
            >
              {minSum ? billPercents[i] + '%' : val + '₸'}
            </div>))}
            <div key="other_tips" onClick={() => {
              setIsCustomTips(true)
              setShowCustomTips(true)
            }}
              className={isCustomTips ? 'bill-quick-amount__item quick-amount__item_theme_active' : 'bill-quick-amount__item'}>
              <p className="small-caption">другая<br />сумма</p>
            </div>
          </div>}
          {showCustomTips && <div className="another-tip-sum">
            <div className="another-tip-sum__actions">
              <div className="another-tip-sum__btn" onClick={() => {
                setShowCustomTips(false)
                if (tipSum === 0) {
                  setIsCustomTips(false)
                }
              }}>
                <IoMdArrowBack style={{ width: 45, height: 45 }} />
              </div>
              <div className="another-tip-sum__amount">
                <div className="amount__value">
                  <input
                    autoFocus={true}
                    className="amount__input"
                    type="number"
                    value={tipSum}
                    pattern="\d*"
                    onChange={(e) => setTipSum(int(e.target.value))}
                  />
                </div>
              </div>
              <div className="another-tip-sum__btn">
                {tipSum > 200 ? <AiOutlineCheck style={{ width: 45, height: 45, color: "#f2c94c" }} /> :
                  <AiOutlineCheck style={{ width: 45, height: 45, color: "#ECECEC" }} />}
              </div>
            </div>
            <div className="another-tip-sum__small-caption gray-text">
              {t('minimumAmount') + " 200 ₸"}
            </div>
          </div>}
          <div className={withOrder ? "bill__switcher" : "bill__switcher gray-text"}>
            <div>Оплатить счет</div>
            <div>
              <SwitchBtn checked={withOrder} onChange={() => setWithOrder(!withOrder)} />
            </div>
          </div>
        </div>}
        {userNumber && userInfo && (<div className="pay-block">
          {(s === 0 || !userInfo.company.billPay) && <div className="tips__panel">
            <div className="amount__caption small-caption gray-text">
              {s ? `${t('totalOnTheReceipt')} ` + s + ' ₸' : t('enterTheAmount')}
            </div>
            <div className="amount__value">
              <input
                autoFocus={true}
                className="amount__input"
                type="number"
                value={tipSum}
                pattern="\d*"
                required={true}
                onChange={(e) => setTipSum(e.target.value)}
              />
            </div>
            <div className="quick-amount">
              {quickAmounts.map((val, i) => (<div key={i} onClick={() => setTipSum(sumPercent(val, percents[i]))}
                className={tipSum === sumPercent(val, percents[i]) ? 'quick-amount__item quick-amount__item_theme_active' : 'quick-amount__item'}>
                {minSum ? percents[i] + '%' : val}
              </div>))}
            </div>
          </div>}
          <div className="estimate tips__estimate">
            <div className="small-caption gray-text">{t('rateMe')}</div>
            <div className="estimate__starts">
              <div className="estimate__star" onClick={() => { pickEstimate(1) }}>
                {estimate[0] ? (<ImgStarActive width="30" height="30" />) : (<ImgStar width="30" height="30" />)}
              </div>
              <div className="estimate__star" onClick={() => { pickEstimate(2) }}>
                {estimate[1] ? (<ImgStarActive width="30" height="30" />) : (<ImgStar width="30" height="30" />)}
              </div>
              <div className="estimate__star" onClick={() => { pickEstimate(3) }}>
                {estimate[2] ? (<ImgStarActive width="30" height="30" />) : (<ImgStar width="30" height="30" />)}
              </div>
              <div className="estimate__star" onClick={() => { pickEstimate(4) }}>
                {estimate[3] ? (<ImgStarActive width="30" height="30" />) : (<ImgStar width="30" height="30" />)}
              </div>
              <div className="estimate__star" onClick={() => { pickEstimate(5) }}>
                {estimate[4] ? (<ImgStarActive width="30" height="30" />) : (<ImgStar width="30" height="30" />)}
              </div>
            </div>
            {estimateValue !== null && estimateValue >= 4 && (<div className="fast_comments_box">
              <div>{t('whatDidYouLike')}</div>
              <div className="fast_comments">
                <FastCommentButton
                  getIcon={(selected) => (<AtmospherePositiveIcon isColored={selected} />)}
                  text={t('atmospherePositive')}
                  buttonHandler={() => quickCommentHandler('atmospherePositive')}
                />
                <FastCommentButton
                  getIcon={(selected) => (<CuisinePositiveIcon isColored={selected} />)}
                  text={t('cuisinePositive')}
                  buttonHandler={() => quickCommentHandler('cuisinePositive')}
                />
                <FastCommentButton
                  getIcon={(selected) => (<ServicePositiveIcon isColored={selected} />)}
                  text={t('servicePositive')}
                  buttonHandler={() => quickCommentHandler('servicePositive')}
                />
              </div>
            </div>)}
            {estimateValue !== null && estimateValue <= 3 && (<div className="fast_comments_box">
              <div>{t('whatYouDidntLike')}</div>
              <div className="fast_comments">
                <FastCommentButton
                  getIcon={(selected) => (<AtmosphereNegativeIcon isColored={selected} />)}
                  text={t('atmosphereNegative')}
                  buttonHandler={() => quickCommentHandler('atmosphereNegative')}
                />
                <FastCommentButton
                  getIcon={(selected) => (<CuisineNegativeIcon isColored={selected} />)}
                  text={t('cuisineNegative')}
                  buttonHandler={() => quickCommentHandler('cuisineNegative')}
                />
                <FastCommentButton
                  getIcon={(selected) => (<ServiceNegativeIcon isColored={selected} />)}
                  text={t('serviceNegative')}
                  buttonHandler={() => quickCommentHandler('serviceNegative')}
                />
              </div>
            </div>)}
            {estimateValue > 0 && (<div className="estimate__comment">
              <ImgInput theme="light" field={comment} onChange={(e) => setComment(e.target.value)} placeholder={t('leaveAComment')} />
            </div>)}
          </div>
          <div className="tips__actions">
            {showApplePay && <div className="tips__action-item pay-apple">
              <ActionButtonWhite onClick={() => beforePay(true)} text={<><ImgApple /> Pay</>} disabled={!amount || applePayLoader}
                loader={applePayLoader}
              />
            </div>}
            <div className="tips__action-item pay-others">
              <ActionButtonWhite onClick={() => beforePay()} text={showApplePay ? t('otherMethods') : t('pay')} disabled={!amount}
                loader={payLoader} />
            </div>
          </div>
          <div className="tips__bubble" />
          <div className="tips-footer">
            <a target="_blank" href={config.publicUrl + '/contacts?light'} className="tips-footer__contacts"
              rel="noreferrer">
              {t('contactUs')}
            </a>
          </div>
        </div>)}
      </>)}
    </div>
  </div>)
}
