import React, { useState, useContext } from "react";

import "./Registration.scss";

import useApi from "../../lib/hooks/useAPI";

import { AuthContext } from "../../lib/auth/Context";
import useNotify from "../../lib/hooks/Notify";
import FlatBtn from "../common/FlatBtn";
import { Link } from "react-router-dom";
import { ImgInput, PhoneInput } from "../common/ImgInput";

import { ReactComponent as ImgProfile } from "../common/img/profile.svg";
import { ReactComponent as ImgPhone } from "../common/img/phone.svg";
import { ReactComponent as ImgLock } from "../common/img/lock.svg";
import { ReactComponent as ImgLetter } from "../common/img/letter.svg";

import ActionButton from "../common/ActionButton";
import CountDownTimer from "../common/CountDownTimer";
import { useTranslation } from "react-i18next";

export default function Registration() {
  const { dispatch } = useContext(AuthContext);
  const api = useApi();
  const notify = useNotify();

  const [phone, setPhone] = useState("");
  const [name, setName] = useState("");
  const [password, setPassword] = useState("");
  const [code, setCode] = useState("");
  const [checkPassword, setCheckPassword] = useState("");

  const [isShowLoader, setIsShowLoader] = useState(false);
  const [showCode, setShowCode] = useState(false);
  const [oneMoreCode, setOnMoreCode] = useState(false);
  const [sendCodeLoader, setSendCodeLoader] = useState(false);
  const [approveLoader, setApproveLoader] = useState(false);

  const { t } = useTranslation();

  const onPhoneChange = (val) => {
    setPhone("+" + val.replace(/\D/g, ""));
  };

  const handleRegister = async () => {
    if (password !== checkPassword) {
      notify({ title: t("passwordsDontMatch") });
      return;
    }

    setSendCodeLoader(true);
    setIsShowLoader(true);
    setShowCode(false);
    const registerRes = await api.post("user.register", {
      name: name,
      phone: phone,
      password: password,
    });
    setSendCodeLoader(false);
    if (!registerRes.success) {
      setIsShowLoader(false);
      return;
    }

    if (registerRes.data.entry.token) {
      authUser(phone, registerRes.data.entry.token);
      return;
    }

    setIsShowLoader(false);
    setShowCode(true);
  };

  const handleApprove = async () => {
    setIsShowLoader(true);
    setApproveLoader(true);
    const approveRes = await api.post("user.approve", {
      phone: phone,
      code: code,
    });
    if (!approveRes.success) {
      setIsShowLoader(false);
      setApproveLoader(false);
      return;
    }
    const authRes = await api.get("auth.getToken", {
      login: phone,
      password: password,
    });
    if (!authRes.success) {
      setIsShowLoader(false);
      setApproveLoader(false);
      return;
    }
    authUser(authRes.data);
  };

  const authUser = async (payload) => {
    if (!isShowLoader) {
      setIsShowLoader(true);
    }
    api.state.token = payload.toString();

    dispatch({
      type: "login",
      payload: payload,
    });
    setIsShowLoader(false);

    notify({ type: "success", title: t("youHaveSuccessfullyRegistered") });
    setTimeout(() => window.location.replace("/main"), 1500);
  };

  return (
    <div className="registration central">
      <div className="app-container registration__app-container">
        <div className="registration__header">
          <Link to="/">
            <FlatBtn type="prev" />
          </Link>
          <p className="header-text">{t("registration")}</p>
        </div>
        {!showCode && (
          <div className="registration__inputs">
            <div className="registration__input">
              <ImgInput
                image={<ImgProfile />}
                placeholder={t("name")}
                onChange={(e) => setName(e.target.value)}
              />
            </div>
            <div className="registration__input">
              <PhoneInput
                image={<ImgPhone />}
                placeholder={t("phoneNumber")}
                onChange={(e) => onPhoneChange(e.currentTarget.value)}
                field={phone}
              />
            </div>
            <div className="registration__input">
              <ImgInput
                image={<ImgLock />}
                placeholder={t("password")}
                type="password"
                onChange={(e) => setPassword(e.target.value)}
              />
            </div>
            <div className="registration__input">
              <ImgInput
                image={<ImgLock />}
                placeholder={t("repeatPassword")}
                type="password"
                onChange={(e) => setCheckPassword(e.target.value)}
              />
            </div>
          </div>
        )}
        {showCode && (
          <div className="registration__inputs">
            <div className="registration__input">
              <ImgInput
                image={<ImgLetter />}
                placeholder={t("smsCode")}
                onChange={(e) => setCode(e.target.value)}
              />
            </div>
          </div>
        )}
        <div className="registration__controls">
          {!showCode && (
            <ActionButton
              text={t("sendCode")}
              onClick={() => handleRegister()}
              loader={sendCodeLoader}
            />
          )}
          {showCode && (
            <div>
              <ActionButton
                text={t("confirm")}
                disabled={code.length < 4}
                onClick={() => handleApprove()}
                loader={approveLoader}
              />
              {!oneMoreCode && (
                <p className="registration__double-sent-text gray-text">
                  {t("sendCodeAgainAfter")}{" "}
                  <CountDownTimer
                    start={180}
                    onFinish={() => setOnMoreCode(true)}
                  />
                </p>
              )}
              {oneMoreCode && (
                <p
                  className="registration__double-sent-text"
                  onClick={() => handleRegister()}
                >
                  {t("sendCodeAgain")}
                </p>
              )}
            </div>
          )}
        </div>
      </div>
    </div>
  );
}
