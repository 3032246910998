import React, { useEffect, useState } from "react";

import "./EmployeeAdd.scss";

import useApi from "../../lib/hooks/useAPI";
import FlatBtn from "../common/FlatBtn";

import ActionButton from "../common/ActionButton";

import { useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { Employee } from "../common/Employee";
import Loader from "../common/Loader";
import { PhoneInput } from "../common/ImgInput";
import { BsCheck } from "react-icons/bs";
import { ReactComponent as ImgSuccess } from "../common/img/success_circle.svg";
import { ReactComponent as ImgPlus } from '../common/img/plus.svg';
import { ReactComponent as ImgPhone } from "../common/img/phone.svg";
import { AvatarSmallCircle } from "../common/AvatarCircle";
import ImageItem from "../common/ImageItem";

function ImgAvatarEmpty() {
  return null;
}

export default function EmployeeAdd({ onClose }) {
  const api = useApi();

  const [unlinkedUsers, setUnlinkedUsers] = useState([]);
  const [phoneUser, setPhoneUser] = useState(null);
  const [phone, setPhone] = useState([])
  const [success, setSuccess] = useState(false);

  const [usersListLoader, setUsersListLoader] = useState(true);
  const [phoneUserLoader, setPhoneUserLoader] = useState(false);
  const [saveLoader, setSaveLoader] = useState(false);

  const [isDisabledButton, setIsDisabledButton] = useState(true)

  const { companyId } = useParams();

  const { t } = useTranslation();

  const addEmployeesHandler = async () => {
    setSaveLoader(true);
    let nums = []
    unlinkedUsers.forEach(u => {
      if (u.isManager) {
        nums.push(u.number)
      }
    })

    const res = await api.post("company.addEmployees", {
      userNumbers: nums,
      companyId: companyId,
    });
    setSaveLoader(false);
    if (!res.success) {
      return;
    }

    setSuccess(true);
    setTimeout(() => window.location.reload(), 500);
  };

  const getUser = async (phone) => {
    setPhoneUserLoader(true);
    const res = await api.get("user.get", { phone: phone });
    setPhoneUserLoader(false);
    if (!res.success || !res.data.users) {
      return;
    }
    setPhoneUser(res.data.users[0]);
  };

  const onPhoneChange = (val) => {
    const cleanPhone = "+" + val.replace(/\D/g, "");
    setPhone(cleanPhone);
    if (cleanPhone.length === 12) {
      getUser(cleanPhone);
    } else {
      setPhoneUser(null);
    }
  };

  const getUnlinkedUsers = async () => {
    const res = await api.get("user.getUnlinked");
    setUsersListLoader(false);
    if (!res.success || !res.data.users) {
      return;
    }
    setUnlinkedUsers(res.data.users);
  };

  const onClickItem = (pickedManager) => {
    const users = unlinkedUsers.map((emp) => {
      if (emp.id === pickedManager.id) {
        emp.isManager = !emp.isManager
      }
      return emp
    })
    setUnlinkedUsers(users)
    let disabled = !users.filter(u => u.isManager).length
    setIsDisabledButton(disabled)
  }

  useEffect(() => {
    getUnlinkedUsers();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <div className="admin central noselect">
      <div className="app-container admin__app-container">
        <div className="admin__header">
          <FlatBtn onClick={ onClose } type="prev"/>
          <p className="header-text">{ t("inviteEmployee") }</p>
        </div>
        { usersListLoader &&
        <div className="d-flex flex-column justify-content-center flex-grow-1">
          <Loader type="dot"/>
        </div>
        }
        { !success &&
        <>
          <div className="employee-add__list">
            { unlinkedUsers.map(emp => (
              <Employee
                key={ emp.id }
                emp={ emp }
                onClickRightIcon={ () => onClickItem(emp) }
                rightIcon={ emp.isManager ? <BsCheck/> : <ImgPlus/> }
              />)
            )
            }
          </div>
          <div className="admin__center justify-content-center align-items-center employee__phone">
            <PhoneInput
              value={phone}
              image={ <ImgPhone/> }
              placeholder={ t("phoneNumber") }
              onChange={ e => onPhoneChange(e.target.value) }
            />
            <div className="employee-add__list">
              { phoneUserLoader &&
              <div className="d-flex flex-column justify-content-center flex-grow-1">
                <Loader type="dot"/>
              </div>
              }
              { phoneUser &&
              <ImageItem avatar={ phoneUser.photo
                ? <AvatarSmallCircle photo={ phoneUser.photo }/>
                : <ImgAvatarEmpty/> }
                         text={ phoneUser.name + " " + phoneUser.surname }
                         onClickItem={ () => {
                           setUnlinkedUsers([...unlinkedUsers, phoneUser])
                           setPhone("")
                           setPhoneUser(null)
                         } }
              />
              }
            </div>
          </div>
          <div className="admin__footer">
            <ActionButton
              onClick={ addEmployeesHandler }
              text={ t("invite") }
              disabled={isDisabledButton}
              loader={ saveLoader }
            />
          </div>
        </>
        }
        { success &&
        <div className="admin__center justify-content-center align-items-center">
          <div className="circle-success">
            <ImgSuccess/>
            <p className="circle-success__text success">{ t("success") }</p>
          </div>
        </div>
        }
      </div>
    </div>
  );
}
