import {t} from "i18next";

export const getCategories = () => {

    return [
        {
            id: "restaurants",
            name: t("restaurants")
        },
        {
            id: "karaoke",
            name: t("karaoke")
        },
        {
            id: "hotels",
            name: t("hotels")
        },
        {
            id: "delivery",
            name: t("delivery")
        },
        {
            id: "beauty",
            name: t("beauty")
        },
        {
            id: "gas-station",
            name: t("gasStation")
        },
    ]
}