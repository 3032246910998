import React, {useCallback, useEffect, useState} from "react";

import "../EmployeeAdd.scss";


import useApi from "../../../lib/hooks/useAPI";
import FlatBtn from "../../common/FlatBtn";

import ActionButton from "../../common/ActionButton";


import {ReactComponent as ImgSuccess} from "../../common/img/success_circle.svg";
import {ReactComponent as ImgPlus} from '../../common/img/plus.svg';

import Loader from "../../common/Loader";
import {useTranslation} from "react-i18next";
import {useParams} from "react-router-dom";
import {Employee} from "../../common/Employee";
import {BsCheck} from "react-icons/bs";

export const ManagerAdd = ({companyUpdateManagers, onClose}) => {
    const api = useApi();

    const { companyId } = useParams();

    const [success, setSuccess] = useState(false);
    const [isLoaded, setIsLoaded] = useState(true);
    const [addLoader, setAddLoader] = useState(false);
    const [users, setUsers] = useState([]);
    const [isDisabledButton, setIsDisabledButton] = useState(true)

    const {t} = useTranslation();

    const onUpdateManagers = async () => {
        setAddLoader(true);
        let nums = []
        users.forEach(u => {
            if (u.isManager) {
                nums.push(u.number)
            }
        })
        await companyUpdateManagers(nums);
        setAddLoader(false);

        setSuccess(true);
    };

    useEffect(() => {
        getEmployees();
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    const getEmployees = useCallback(async () => {
        const res = await api.get("user.get", {
            companyId: companyId,
        });
        setIsLoaded(false)
        if (!res.success || !res.data.users) {
            return;
        }
        setUsers(res.data.users);
    }, []);

    const onRightClickItem = (pickedManager) => {
        const tmp = users.map((emp) => {
            if (emp.id === pickedManager.id) {
                emp.isManager = !emp.isManager
            }
            return emp
        })
        setUsers(tmp)
        let disabled = !users.filter(u => u.isManager).length
        setIsDisabledButton(disabled)
    }


    return (
        <div className="admin central noselect">
            <div className="app-container admin__app-container">
                <div className="admin__header">
                    <FlatBtn onClick={onClose} type="prev"/>
                    <p className="header-text">{t("addManager")}</p>
                </div>
                {!success &&
                <>
                    <div className="admin__center justify-content-center align-items-center">


                        <div className="employee-add__list">
                            {isLoaded &&
                            <div className="d-flex flex-column justify-content-center flex-grow-1">
                                <Loader type="dot"/>
                            </div>
                            }

                                <div className="employee-list flex-column justify-content-center">
                                    { users.map((emp) => (
                                        <Employee
                                            key={emp.id}
                                            emp={emp}
                                            onClickRightIcon={ () => onRightClickItem(emp) }
                                            rightIcon={ emp.isManager ? <BsCheck/> : <ImgPlus/> }
                                        />
                                    ))}
                                </div>



                        {/*    спискок сотрудников  из результата getEmployees */}

                        </div>
                    </div>
                    <div className="admin__footer">
                        <ActionButton
                            onClick={onUpdateManagers}
                            text={t("add")}
                            disabled={isDisabledButton}
                            loader={addLoader}
                        />
                    </div>

                </>
                }
                {success &&
                <div className="admin__center justify-content-center align-items-center">
                    <div className="circle-success">
                        <ImgSuccess/>
                        <p className="circle-success__text success">{t("success")}</p>
                    </div>
                </div>
                }
            </div>
        </div>
    );
}
