import React from "react";
import ImageItem from "../../common/ImageItem";

import { ReactComponent as ImgAvatarEmpty } from "../../common/img/avatar_empty.svg";
import { AvatarSmallCircle } from "../../common/AvatarCircle";
import Loader from "../../common/Loader";

export const AccountItem = React.memo(({emp, onClickItem, onClickRightIcon, onRightLoader, rightIcon}) => (
  <div className="employee-list__item">
    <ImageItem
      onClickItem={onClickItem}
      avatar={emp.photo ? (
        <AvatarSmallCircle photo={emp.photo} />
      ) : (
        <ImgAvatarEmpty />
      )}
      text={emp.name + " ID: " + emp.number + " (Balance: " + emp.balance + " ₸)"}
      rightIcon={onRightLoader ? <Loader type="circle" /> : (rightIcon ?? <></>) }
      onClickRightIcon={onClickRightIcon}
    />
  </div>
));
