import { useContext, useEffect, useState } from "react";
import AWS from "aws-sdk";
import heic2any from "heic2any";
import { AuthContext } from "../../../lib/auth/Context";
import useNotify from "../../../lib/hooks/Notify";
import useAPI from "../../../lib/hooks/useAPI";

const S3_BUCKET = "coinbox.kz";
const REGION = "us-east-2";
const ACCESS_KEY = "AKIA6NJ7CTKD7PHYYRNG";
const SECRET_ACCESS_KEY = "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";

AWS.config.update({
  accessKeyId: ACCESS_KEY,
  secretAccessKey: SECRET_ACCESS_KEY,
});

const myBucket = new AWS.S3({
  params: { Bucket: S3_BUCKET },
  region: REGION,
});

export const useAvatar = (userId, companyId, setUser, setCompany) => {
  const api = useAPI();

  const { state } = useContext(AuthContext);
  const [imgLoading, setImgLoading] = useState(false);
  const [progress, setProgress] = useState(0);
  const [crop, setCrop] = useState({
    unit: "px", // default, can be 'px' or '%'
    x: 100,
    y: 50,
    width: 100,
    height: 100,
    minHeight: 100,
    minWidth: 100,
    aspect: 1,
  });
  const [src, setSrc] = useState(null);
  const [blobFile, setBlobFile] = useState(null);
  const [imageRef, setImageRef] = useState(null);

  const notify = useNotify();

  useEffect(() => {
    if (!imageRef) {
      return;
    }
    makeClientCrop(crop);
  }, [imageRef]); // eslint-disable-line react-hooks/exhaustive-deps

  let fileUrl;

  const onImageLoaded = (image) => {
    setImageRef(image);
  };

  const onCropComplete = (crop) => {
    makeClientCrop(crop);
  };

  const onCropChange = (crop, percentCrop) => {
    setCrop(crop);
  };

  // let hiddenFile;

  const onPhotoChangeClick = (e) => {
    setImgLoading(true);
    const fileName = e.target.files[0].name;
    const fileNameExt = fileName
      .substr(fileName.lastIndexOf(".") + 1)
      .toLowerCase();
    if (fileNameExt === "heic") {
      const blob = e.target.files[0];
      heic2any({
        blob: blob,
        toType: "image/jpg",
      })
        .then((resultBlob) => {
          setImgLoading(false);
          const fileReader = new FileReader();
          fileReader.onloadend = () => {
            setSrc(fileReader.result);
          };
          fileReader.readAsDataURL(resultBlob);
        })
        .catch(function (x) {
          setImgLoading(false);
          notify({ title: x.code, description: x.message });
        });
    } else {
      setImgLoading(false);
      const fileReader = new FileReader();
      fileReader.onloadend = () => {
        setSrc(fileReader.result);
      };
      fileReader.readAsDataURL(e.target.files[0]);
    }
  };

  const makeClientCrop = async (crop) => {
    if (imageRef && crop.width && crop.height) {
      let fileName = `${userId ? userId : companyId}_avatar.jpeg`;
      fileName = state.id + fileName;

      if (process.env.NODE_ENV !== "production") {
        fileName = "local_" + fileName;
      }

      const url = await getCroppedImg(imageRef, crop, fileName);
      userId
        ? setUser(pv => ({ ...pv, photo: url }))
        : setCompany(pv => ({ ...pv, photo: url }));
    }
  };

  const getCroppedImg = (image, crop, fileName) => {
    const canvas = document.createElement("canvas");
    const pixelRatio = window.devicePixelRatio;
    const scaleX = image.naturalWidth / image.width;
    const scaleY = image.naturalHeight / image.height;
    const ctx = canvas.getContext("2d");

    canvas.width = crop.width * pixelRatio * scaleX;
    canvas.height = crop.height * pixelRatio * scaleY;

    ctx.setTransform(pixelRatio, 0, 0, pixelRatio, 0, 0);
    ctx.imageSmoothingQuality = "high";

    ctx.drawImage(
      image,
      crop.x * scaleX,
      crop.y * scaleY,
      crop.width * scaleX,
      crop.height * scaleY,
      0,
      0,
      crop.width * scaleX,
      crop.height * scaleY
    );

    const canvasResized = document.createElement("canvas");
    canvasResized.width = 100;
    canvasResized.height = 100;
    const ctxResized = canvasResized.getContext("2d");
    ctxResized.drawImage(canvas, 0, 0, 100, 100);

    return new Promise((resolve, reject) => {
      canvasResized.toBlob(
        (blob) => {
          if (!blob) {
            //reject(new Error('Canvas is empty'));
            console.error("Canvas is empty");
            return;
          }
          blob.name = fileName;
          setBlobFile(blob);
          window.URL.revokeObjectURL(fileUrl);
          fileUrl = window.URL.createObjectURL(blob);
          resolve(fileUrl);
        },
        "image/jpeg",
        1
      );
    });
  };

  const getProfile = userId ? "user_profile/" : "company_profile/";

  const uploadFile = () => {
    const params = {
      ACL: "public-read",
      Body: blobFile,
      Bucket: S3_BUCKET,
      Key: getProfile + blobFile.name,
    };

    myBucket
      .putObject(params)
      .on("httpUploadProgress", (evt) => {
        setProgress(Math.round((evt.loaded / evt.total) * 100));
      })
      .on("success", (resp) => {
        if (resp.error) {
          notify(resp.error.message);
          return;
        }
        updatePhoto(
          "https://s3.us-east-2.amazonaws.com" + resp.request.httpRequest.path
        );
      })
      .send((err) => {
        if (err) notify(err.message);
      });
  };

  const updatePhoto = async (photo) => {
    if (companyId) {
      const res = await api.post("company.update", {
        photo: photo,
        companyId: companyId,
      });
      if (!res.success) {
        return;
      }
    } else {
      const res = await api.post(
        "user.update",
        userId
          ? {
              photo: photo,
              userId: userId,
            }
          : { photo: photo }
      );
      if (!res.success) {
        return;
      }
    }
    window.location.reload();
  };

  return {
    onPhotoChangeClick: onPhotoChangeClick,
    onImageLoaded: onImageLoaded,
    onCropComplete: onCropComplete,
    onCropChange: onCropChange,
    uploadFile: uploadFile,
    progress: progress,
    src,
    crop,
    imgLoading
  };
};
