export const Selector = ({
                             value,
                             onChangeHandler,
                             optionPlaceholder,
                             loading,
                             contentArray,
                             icon
                         }) => {
    return <div className="input__item">
        <div className="lined-input">
            <div className="lined-input__img">
                {icon}
            </div>
            <div className="lined-input__text">
                <select
                    className="no-background"
                    value={value ? value : ""}
                    onChange={e => onChangeHandler(e.currentTarget.value)}
                >
                    <option
                        value=""
                        disabled
                        className="gray-text"
                    >
                        {optionPlaceholder}
                    </option>
                    {
                        contentArray !== null
                        && !loading
                        && <>
                            {contentArray?.map(contentItem => <option
                                value={contentItem["id"]}
                                key={contentItem["id"]}
                                className="gray-text"
                            >
                                {contentItem["name"]}
                            </option>)}
                        </>
                    }
                </select>
            </div>
        </div>
    </div>
}