import ActionButton from "../ActionButton";
import styles from "./LoadMore.module.scss";

export const LoadMore = ({
  renderCondition,
  clickHandler,
  loadingCondition,
  buttonText,
  fullListText,
}) => {
  return (
    <>
      {renderCondition ? (
        <ActionButton
          onClick={clickHandler}
          text={buttonText}
          loader={loadingCondition}
        />
      ) : (
        !loadingCondition && (
          <div className={styles["full-list"]}>{fullListText}</div>
        )
      )}
    </>
  );
};
