import React from 'react';
import config from "../../config";

export const AuthContext = React.createContext();

export const InitialState = {
    apiUrl: "",
    isAuthenticated: false,
    login: null,
    token: null,
    id: null,
    number: null,
    rights: null,
    notifier: null,
}

export const authReducer = (state, action) => {
    switch (action.type) {
        case "login":
            localStorage.setItem("login", action.payload.login);
            localStorage.setItem("token", action.payload.token);
            localStorage.setItem("id", action.payload.id);
            localStorage.setItem("number", action.payload.number);
            localStorage.setItem("rights", action.payload.rights ?? config.right.tips);
            return {
                ...state,
                isAuthenticated: true,
                login: action.payload.login,
                token: action.payload.token,
                id: action.payload.id,
                number: action.payload.number,
                rights: action.payload.rights ?? config.right.tips
            };
        case "logout":
            localStorage.clear();
            return {
                ...state,
                apiUrl: "",
                isAuthenticated: false,
                login: null,
                token: null,
                id: null,
                number: null,
                rights: null,
            };
        default:
            return state;
    }
}

export const getInitialState = (notify) => {
    InitialState.apiUrl = config.apiUrl;
    InitialState.login = localStorage.getItem("login");
    InitialState.token = localStorage.getItem("token");
    InitialState.id = localStorage.getItem("id");
    InitialState.number = parseInt(localStorage.getItem("number"));
    InitialState.rights = parseInt(localStorage.getItem("rights"));
    InitialState.notifier = notify;

    if (InitialState.login
        && InitialState.token
        && InitialState.id
        && InitialState.number
        && InitialState.rights) {
        InitialState.isAuthenticated = true;
    }

    return InitialState
}
