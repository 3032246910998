import React from "react";
import { Link } from "react-router-dom";
import { ReactComponent as ImgProfileGray } from "../../common/img/avatar_empty.svg";
import ImageItem from "../../common/ImageItem";
import {AvatarSmallCircle} from "../../common/AvatarCircle";

export const Company = ({company, to, rightIcon}) => {
  return (
    <Link
      to={to}
    >
      <div className="company__item">
        <ImageItem
          avatar={company?.photo ? <AvatarSmallCircle photo={`${company.photo}?${global.Date.now()}`}/> : <ImgProfileGray />}
          text={`${company.name} (${company.number})`}
          rightIcon={rightIcon}
        />
      </div>
    </Link>
  );
};
