import './Loader.scss';

import {ReactComponent as ImgDotLoader} from  "../common/img/dot_loader.svg";
import {ReactComponent as ImgCircleLoader} from  "../common/img/circle_loader.svg";

export default function Loader({type}) {
    let loader;
    switch (type) {
        case "circle":
            loader = <ImgCircleLoader/>
            break
        case "dot":
            loader = <ImgDotLoader/>
            break
        default:
            loader = <ImgCircleLoader/>
    }

    return (
        <div className="loader__central">
            {loader}
        </div>
    )
}