import React from 'react';
import { Document, Page, pdfjs } from "react-pdf";
import samplePDF from './info.pdf';

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

export default function Agreement() {

    return (
        <div className="central">
            <Document file={samplePDF}>
                <Page pageNumber={1} />
                <Page pageNumber={2} />
                <Page pageNumber={3} />
                <Page pageNumber={4} />
            </Document>
        </div>
    );
}