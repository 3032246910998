import React, {useState} from 'react';

import './ProfileEdit.scss';

import useApi from "../../lib/hooks/useAPI";

import FlatBtn from "../common/FlatBtn";
import ActionButton from "../common/ActionButton";

import {ImgInput} from "../common/ImgInput";
import {ReactComponent as ImgSuccess} from "../common/img/success_circle.svg";
import {ReactComponent as ImgLock} from "../common/img/lock.svg";
import useNotify from "../../lib/hooks/Notify";
import {useTranslation} from "react-i18next";

export default function ProfileEditPass({user, onClose}) {

    const api = useApi();
    const notify = useNotify();

    const [password, setPassword] = useState("");
    const [repeatPass, setRepeatPass] = useState("");

    const [showSuccess, setShowSuccess] = useState(false);
    const [saveLoader, setSaveLoader] = useState(false);

    const {t} = useTranslation();

    const userUpdate = async () => {
        if (password !== repeatPass) {
            notify({title: t("passwordsDontMatch")})
            return
        }

        setSaveLoader(true);
        const res = await api.post("user.update", {userId: user.id, password: password});
        setSaveLoader(true);
        if (!res.success) {
            return;
        }
        setShowSuccess(true);
        setTimeout(() => window.location.reload(), 1000);
    }

    return (
        <div className="admin central noselect modal-padding">
            <div className="app-container admin__app-container">
                <div className="admin__header">
                    <FlatBtn type="prev" onClick={() => onClose()}/>
                    <p className="header-text">{t("changePassword")}</p>
                </div>
                <div className="admin__center">
                    {!showSuccess &&
                        <div className="profile-edit">
                            <div className="profile-edit__item">
                                <ImgInput
                                    placeholder={t("password")}
                                    field={password}
                                    onChange={e => setPassword(e.target.value)}
                                    image={<ImgLock/>}
                                    type="password"
                                />
                            </div>
                            <div className="profile-edit__item">
                                <ImgInput
                                    placeholder={t("repeatPassword")}
                                    field={repeatPass}
                                    onChange={e => setRepeatPass(e.target.value)}
                                    image={<ImgLock/>}
                                    type="password"
                                />
                            </div>
                        </div>
                    }
                    {showSuccess &&
                        <div className="circle-success flex-grow-1">
                            <ImgSuccess/>
                            <p className="circle-success__text success">{t("updatedPassword")}</p>
                        </div>
                    }
                </div>
                <div className="admin__footer">
                    {!showSuccess && <ActionButton
                        onClick={(e) => userUpdate(e)}
                        text={t("save")}
                        disabled={!password || !repeatPass}
                        loader={saveLoader}
                    />}
                </div>
            </div>
        </div>
    );
}
