import React, { useState, useContext } from "react";
import { Link } from "react-router-dom";

import useApi from "../../lib/hooks/useAPI";
import { AuthContext } from "../../lib/auth/Context";

import "./Auth.scss";
import useNotify from "../../lib/hooks/Notify";
import { checkPhoneWithPlus } from "../../lib/helpers/validation";

import ActionButton from "../common/ActionButton";
import { ImgInput, PhoneInput } from "../common/ImgInput";

import { ReactComponent as ImgLogo } from "../common/img/logo_light.svg";
import { ReactComponent as ImgPhone } from "../common/img/phone.svg";
import CountDownTimer from "../common/CountDownTimer";
import { useTranslation } from "react-i18next";

export default function Auth() {
  const api = useApi();
  const { dispatch } = useContext(AuthContext);
  const notify = useNotify();

  const [login, setLogin] = useState("");
  const [password, setPassword] = useState("");

  const [authLoader, setAuthLoader] = useState(false);
  const [smsLoader, setSmsLoader] = useState(false);

  const [oneMoreCode, setOnMoreCode] = useState(true);

  const { t } = useTranslation();

  const onPhoneChange = (val) => {
    setLogin("+" + val.replace(/\D/g, ""));
  };

  const handleSubmit = async () => {
    if (!login) {
      notify({ title: t("enterThePhoneNumber") });
      return;
    }

    if (!password) {
      notify({ title: t("enterThePassword") });
      return;
    }

    if (!checkPhoneWithPlus(login)) {
      notify({ title: t("enterThePhoneNumberUsing+") });
      return;
    }

    setAuthLoader(true);
    const authRes = await api.get("auth.getToken", {
      login: login,
      password: password,
    });
    if (!authRes.success) {
      setAuthLoader(false);
      return;
    }

    api.state.token = authRes.data.token;

    dispatch({ type: "login", payload: authRes.data });
    setAuthLoader(false);
  };

  const handleSms = async () => {
    if (!login) {
      notify({ title: t("enterThePhoneNumber") });
      return;
    }

    if (!checkPhoneWithPlus(login)) {
      notify({ title: t("enterThePhoneNumberUsing+") });
      return;
    }

    setOnMoreCode(false);
    setSmsLoader(true);
    const authRes = await api.get("auth.getSmsCode", { phone: login });
    setSmsLoader(false);
    if (!authRes.success) {
      return;
    }
  };

  return (
    <div className="auth central">
      <div className="app-container auth__app-container">
        <div className="logo auth__logo">
          <div className="logo__image">
            <ImgLogo />
          </div>
          <div className="logo__text">
            <span>CoinBox</span>
          </div>
        </div>
        <div className="auth__content">
          <div className="auth__inputs">
            <div className="input-block__item">
              <PhoneInput
                image={<ImgPhone />}
                placeholder={t("phoneNumber")}
                onChange={(e) => onPhoneChange(e.currentTarget.value)}
                field={login}
              />
            </div>
            <div className="input-block__item">
              <ImgInput
                imageType="lock"
                placeholder={t("password")}
                type="password"
                onChange={(e) => setPassword(e.target.value)}
              />
            </div>
          </div>
        </div>
        <div className="footer">
          <div className="auth__buttons">
            <ActionButton
              text={t("enterTheApplication")}
              disabled={password.length < 3 || login.length < 12}
              onClick={(e) => handleSubmit(e)}
              className="control__button control__button_style_inactive"
              loader={authLoader}
            />
            <div style={{ height: "20px" }} />
            {oneMoreCode && (
              <ActionButton
                text={t("telegramCode")}
                disabled={login.length < 12}
                onClick={(e) => handleSms(e)}
                className="control__button control__button_style_inactive"
                loader={smsLoader}
              />
            )}
            {!oneMoreCode && (
              <p className="registration__double-sent-text gray-text">
                {t("sendCodeAgainAfter")}{" "}
                <CountDownTimer
                  start={180}
                  onFinish={() => setOnMoreCode(true)}
                />
              </p>
            )}
          </div>
          <div className="auth__registration">
            {/*<p className="gray-text">Нет аккаунта? <Link to="/registration" className="auth__registration_link">Зарегистрироваться</Link></p>*/}
          </div>
          <Link to="/contacts">
            <p className="footer__text">{t("contactUs")}</p>
          </Link>
        </div>
      </div>
    </div>
  );
}
