import React, { useReducer } from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";

import "./App.scss";

import { AuthContext, authReducer, getInitialState } from "./lib/auth/Context";
import NotificationProvider, {
  useNotifications,
} from "./lib/notifications/Provider";
import Auth from "./components/auth/Auth";
import Registration from "./components/registration/Registration";
import {Tips} from "./components/tips/Tips";
import CompanyList from "./components/admin/CompanyList";
import { EmployeeList } from "./components/admin/EmployeeList";
import CompanyJoin from "./components/admin/CompanyJoin";
import Money from "./components/admin/Money";
import Admin from "./components/admin/Admin";
import Success from "./components/tips/Success";
import Profile from "./components/admin/Profile";
import Modal from "react-modal";
import Agreement from "./components/tips/Agreement";
import Fail from "./components/tips/Fail";
import Apple from "./components/tips/Apple";
import Contacts from "./components/contacts/Contacts";
import { EditCompanyData } from "./components/admin/EditCompanyData";
import { ManagersList } from "./components/admin/ManagersList";
import CompanyDetail from "./components/admin/CompanyDetail";
import SimpleRegistration from "./components/admin/simpleRegistration/SimpleRegistration";
import { CompanyAccounts } from './components/admin/CompanyAccounts/CompanyAccounts'

function App() {

  const { notify, notifications } = useNotifications();

  const initialState = getInitialState(notify);

  const [state, dispatch] = useReducer(authReducer, initialState);

  Modal.setAppElement("#root");

  const isAuthenticated = (commponent) => {
    if (state.isAuthenticated) {
      return commponent
    } else {
      return <Auth />
    }
  }

  return (
    <Router>
      <AuthContext.Provider value={{ state, dispatch }}>
        <NotificationProvider notify={notify} notifications={notifications}>
          <Switch>
            <Route path="/registration">
              <Registration />
            </Route>
            <Route path="/apple/">
              <Apple />
            </Route>
            <Route path="/tips/success">
              <Success />
            </Route>
            <Route path="/tips/fail">
              <Fail />
            </Route>
            <Route path="/tips/:companyExternalId">
              <Tips />
            </Route>
            <Route path="/agreement">
              <Agreement />
            </Route>
            <Route path="/TIPS/:companyExternalId">
              <Tips />
            </Route>
            <Route path="/company/join">
                {isAuthenticated( <CompanyJoin />)}
            </Route>
            <Route path="/company/:companyId/employee">
              <EmployeeList />
            </Route>
            <Route path="/company/:companyId/managers">
              <ManagersList />
            </Route>
            <Route path="/company/:companyId/edit">
              <EditCompanyData />
            </Route>
            <Route path="/company/:companyId/registration">
              <SimpleRegistration />
            </Route>
            <Route path="/company/:companyId/accounts">
              <CompanyAccounts />
            </Route>
            <Route path="/company/:companyId">
              <CompanyDetail />
            </Route>
            <Route path="/company">
              <CompanyList />
            </Route>
            <Route path="/profile">
                {isAuthenticated(<Profile />)}
            </Route>
            <Route path="/money">
              {isAuthenticated( <Money />)}
            </Route>
            <Route path="/contacts">
              <Contacts />
            </Route>
            <Route path="/">
              {isAuthenticated( <Admin/> )}
            </Route>
          </Switch>
        </NotificationProvider>
      </AuthContext.Provider>
    </Router>
  );
}

export default App;
