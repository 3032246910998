import './SwitchBtn.scss'

export default function SwitchBtn({checked=false, onChange}) {

  return (
    <>
      <label className="switcher">
        <input checked={checked} onChange={onChange} type="checkbox"/>
        <span className="switcher__slider switcher__slider_round"/>
      </label>
    </>
  );
}