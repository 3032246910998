import React, { useEffect, useState } from "react";
import useAPI from "../../lib/hooks/useAPI";
import FlatBtn from "../common/FlatBtn";

import "./CompanyCreate.scss";

import ActionButton from "../common/ActionButton";
import { ImgInput, PhoneInput } from "../common/ImgInput";

import ImageItem from "../common/ImageItem";
import Loader from "../common/Loader";
import SuccessResult from "../common/SuccessResult";

import { GiModernCity } from "react-icons/gi";
import { MdOutlineDescription, MdOutlineFastfood } from "react-icons/md";
import { Selector } from "../common/Selector";
import { getCategories } from "../../Services/Utils/getCategories";
import { useTranslation } from "react-i18next";

export default function CompanyCreate({ onClose }) {
  const api = useAPI();

  const [citiesAndBranches, setCitiesAndBranches] = useState(null);
  const [city, setCity] = useState(null);
  const [branch, setBranch] = useState(null);
  const [address, setAddress] = useState(null);
  const [name, setName] = useState(null);
  const [category, setCategory] = useState(null);
  const [adminPhone, setAdminPhone] = useState([{ id: 0, phone: "" }]);
  const [manager, setManager] = useState(null);

  const [showSuccess, setShowSuccess] = useState(false);
  const [managerLoader, setManagerLoader] = useState(false);
  const [createLoader, setCreateLoader] = useState(false);
  const [loading, setLoading] = useState(false);

  const [morePhoneIsDisabled, setMorePhoneIsDisabled] = useState(true);

  const { t } = useTranslation();

  useEffect(() => {
    getCitiesAndBranches();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const getCompanyCreateParam = () => {
    const managerPhones = adminPhone
      .filter((p1) => p1.phone !== "" && p1.phone.length >= 12)
      .map((p2) => p2.phone);
    return {
      name,
      category,
      cityId: city,
      branchId: branch,
      address: address,
      managerPhones,
    };
  };

  const companyCreate = async () => {
    setCreateLoader(true);
    const res = await api.post("company.create", getCompanyCreateParam());
    if (!res.success) {
      setCreateLoader(false);
      return;
    }
    setCreateLoader(false);
    setShowSuccess(true);
    setTimeout(() => window.location.replace("/company"), 1000);
  };

  const onPhoneChange = (val, index) => {
    const cleanPhone = "+" + val.replace(/\D/g, "");
    setAdminPhone((pv) =>
      pv.map((p) => (p.id === index ? { ...p, phone: cleanPhone } : p))
    );
    if (cleanPhone.length === 12) {
      getUser(cleanPhone);
      setMorePhoneIsDisabled(false);
    } else {
      setManager(null);
    }
  };

  const getUser = async (phone) => {
    setManagerLoader(true);
    const res = await api.get("user.get", { phone: phone });
    if (!res.success || !res.data.users) {
      setManagerLoader(false);
      return;
    }
    setManager(res.data.users[0]);
    setManagerLoader(false);
  };

  const getCitiesAndBranches = async () => {
    setLoading(true);
    const res = await api.get("list.get");
    if (!res.success || !res.data.cities || !res.data.branches) {
      setLoading(false);
      return;
    }
    setCitiesAndBranches(res.data);
    setLoading(false);
  };

  return (
    <div className="admin central">
      <div className="app-container admin__app-container">
        <div className="admin__header">
          <FlatBtn type="prev" onClick={onClose} />
          <p className="header-text">{t("createCompany")}</p>
        </div>
        {citiesAndBranches === null && <Loader type="dot" />}
        {citiesAndBranches !== null && (
          <div className="admin__center">
            {showSuccess && <SuccessResult text={t("success")} />}
            {!showSuccess && (
              <div className="company">
                <Selector
                  value={city}
                  onChangeHandler={setCity}
                  optionPlaceholder={t("city")}
                  loading={loading}
                  contentArray={citiesAndBranches["cities"]}
                  icon={<GiModernCity />}
                />
                <Selector
                  value={branch}
                  onChangeHandler={setBranch}
                  optionPlaceholder={t("department")}
                  loading={loading}
                  contentArray={citiesAndBranches["branches"]}
                  icon={<MdOutlineFastfood />}
                />
                <div className="input__item">
                  <ImgInput
                    imageType="bag"
                    placeholder={t("companyName")}
                    onChange={(e) => setName(e.target.value)}
                    field={name ? name : ""}
                  />
                </div>
                <div className="input__item">
                  <ImgInput
                    imageType="bag"
                    placeholder={t("address")}
                    onChange={(e) => setAddress(e.target.value)}
                    field={address ? address : ""}
                  />
                </div>
                <Selector
                  value={category}
                  onChangeHandler={setCategory}
                  optionPlaceholder={t("category")}
                  loading={loading}
                  contentArray={getCategories()}
                  icon={<MdOutlineDescription />}
                />
                {adminPhone.map((aPhone) => (
                  <div className="input__item">
                    <PhoneInput
                      placeholder={t("adminPhone")}
                      onChange={(e) => onPhoneChange(e.currentTarget.value, aPhone.id)}
                      field={aPhone.phone}
                    />
                  </div>
                ))}
                <div className="input__item">
                  {managerLoader && <Loader type="dot" />}
                  {manager && (
                    <ImageItem
                      avatar={
                        <img
                          src={manager.photo}
                          width="40"
                          height="40"
                          className="img-circle"
                          alt=""
                        />
                      }
                      text={manager.name + " " + manager.surname}
                    />
                  )}
                </div>
                <div className="input__item">
                  {!createLoader && (
                    <ActionButton
                      onClick={() => {
                        setMorePhoneIsDisabled(true);
                        return adminPhone.length < 5
                          ? setAdminPhone((pv) => {
                              const idsList = pv.map((phone) => phone.id);
                              return [
                                ...pv,
                                {
                                  id: idsList[idsList.length - 1] + 1,
                                  phone: "",
                                },
                              ];
                            })
                          : {};
                      }}
                      text={`${t("oneMorePhoneNumber")} (${
                        5 - adminPhone.length
                      } / 5)`}
                      disabled={adminPhone.length === 5 || morePhoneIsDisabled}
                    />
                  )}
                </div>
              </div>
            )}
          </div>
        )}
        {!showSuccess && citiesAndBranches !== null && (
          <div className="admin__footer">
            <ActionButton
              onClick={(e) => companyCreate(e)}
              text={t("create")}
              loader={createLoader}
              disabled={!name}
            />
          </div>
        )}
      </div>
    </div>
  );
}
