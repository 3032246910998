import React, { useState } from "react";
import styles from "./FastCommentButton.module.scss";

export const FastCommentButton = ({ getIcon, text, buttonHandler }) => {
  const [selected, setSelected] = useState(false);

  const clickHandler = () => {
    setSelected((pv) => !pv);
    buttonHandler();
  };

  return (
    <div className={styles["wrapper"]} onClick={() => clickHandler()}>
      {getIcon(selected)}
      <div className={styles["text"]}>{text}</div>
    </div>
  );
};
