export function checkPhoneWithPlus(str) {
    let isnum = /^\d+$/.test(str[0]);
    if (!isnum) {
        return true
    }
    // если все остальные цыфры то просим ввести +
    if (/^\d+$/.test(str.substring(1))) {
        return false
    }

    return true
}