import styles from "./AvatarCircle.module.scss";

import { ReactComponent as ImgAvatarEmpty } from "../../../components/common/img/avatar_empty.svg";

export const AvatarLargeCircle = ({
  photo,
  onClickHandler,
  onLoad,
}) => {
  return (
    <div className={styles["avatar"]} onClick={onClickHandler}>
      {photo ? (
        <>
          <img
            className={styles["avatar-img"]}
            src={`${photo}?${global.Date.now()}`}
            alt={"avatar"}
            onLoad={onLoad}
            loading={"lazy"}
          />
        </>
      ) : (
        <ImgAvatarEmpty />
      )}
    </div>
  );
};

export const AvatarSmallCircle = ({ photo, onLoad = () => {} }) => {
  return (
    <div className={styles["avatar-small"]}>
      {photo ? (
        <>
          <img
            className={styles["avatar-img-small"]}
            src={`${photo}?${global.Date.now()}`}
            alt={"avatar"}
            onLoad={onLoad}
            loading={"lazy"}
          />
        </>
      ) : (
        <ImgAvatarEmpty />
      )}
    </div>
  );
};