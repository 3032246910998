import './ImgButton.scss';

import {ReactComponent as ImgPointer} from './img/right_pointer.svg';

export function ImgButton({image, text, onClick}) {

    return (
        <div className="img-button" onClick={onClick ? onClick : ()=>{}}>
            <div className="img-button__img">
                {image}
            </div>
            <div className="img-button__text">
                {text}
            </div>
            <div className="img-button__pointer">
                <ImgPointer/>
            </div>
        </div>
    )
}
