
import './FlatBtn.scss';

import {ReactComponent as ImgPrevBtn} from './img/prev.svg';
import {ReactComponent as ImgPrevBlack} from './img/prev_black.svg';
import {ReactComponent as ImgBurger} from './img/burger.svg';
import {ReactComponent as ImgBell} from './img/bell.svg';
import {ReactComponent as ImgBellOn} from './img/bell.svg';
import {ReactComponent as ImgPlus} from './img/plus.svg';
import {ReactComponent as ImgCross} from './img/cross.svg';
import {ReactComponent as ImgSearch} from './img/search.svg'

export default function FlatBtn({type, disabled, onClick = ()=>{} }) {

  let className = "flat-btn"
  let img_button;

  switch (type) {
    case 'prev':
      img_button = <ImgPrevBtn  />
      break;
    case 'prev_black':
      img_button = <ImgPrevBlack />
      break;
    case 'burger':
      img_button = <ImgBurger/>
      break;
    case 'bell':
      img_button = <ImgBell/>
      break
    case 'bell_on':
      img_button = <ImgBellOn/>
      break
    case 'plus':
      img_button = <ImgPlus />
      break
    case 'cross':
      img_button = <ImgCross />
      break
    case 'empty':
      img_button = ""
      break
    case 'search':
      img_button = <ImgSearch />
      break
    default:
      throw new Error("unknown square-btn type")
  }

  return (
    <div onClick={onClick} className={className}>
      {img_button}
    </div>
  );
}
